<template>
    <div
        v-if="brands.elements.length > 0"
        class="container"
    >
        <b-row class="brand">
            <b-col
                cols="12"
                md="6"
            >
                <h3>{{ brands.title }}</h3>
            </b-col>
            <b-col
                cols="12"
                md="6"
            >
                <p class="lg">
                    {{ brands.description }}
                </p>
            </b-col>
            <div class="brand-listing">
                <div
                    v-for="brand in filteredBrands"
                    :key="`brand-${brands.elements.indexOf(brand)}`"
                >
                    <img
                        :src="brand.image"
                        :alt="brand.name"
                    >
                </div>
            </div>
        </b-row>
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    export default {
        name: 'HomeBrandListing',
        computed: {
            ...mapState('home', ['brands']),
            filteredBrands() {
                return this.brands.elements.filter((brand) => brand.image);
            },
        },
    };
</script>

<style scoped lang="scss">
.brand {
  h3 {
    width: 80%;
    @include media-breakpoint-down(md) {
      width: 100%;
    }
  }
  .brand-listing {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 40px;
    gap: 8px;
    @include media-breakpoint-up(md) {
      justify-content: space-between;
    }
    @include media-breakpoint-down(sm) {
      margin-top: 16px;
    }
    > div {
      img {
        flex: 0 calc(100% / 9);
        height: 60px;
        object-fit: contain;
        @media screen and (max-width: 991px) {
          flex: 0 calc(100% / 6);
        }
        @media screen and (max-width: 767px) {
          flex: 0 calc(100% / 3);
        }
      }
    }
  }
}
</style>
